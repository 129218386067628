import React, { useContext } from "react";
import { useQuery, useMutation, getApolloContext } from "@apollo/react-hooks";
import { CategoriesQuery } from "../Queries/__generated__/CategoriesQuery";
import { loader } from "graphql.macro";
import { Spinner } from "../Components/Spinner";
import { Category } from "../Components/Category";
import { makeStyles, Fab, Tooltip } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { AddCategoryMutation } from "../Mutations/__generated__/AddCategoryMutation";

interface IProps {}

const useStyles = makeStyles(theme => ({
  addButton: {
    alignSelf: "center",
    margin: theme.spacing(2),
  },
}));

const categoriesQuery = loader("../Queries/CategoriesQuery.gql");
const addCategoryMutation = loader("../Mutations/AddCategoryMutation.gql");
const questionsQuery = loader("../Queries/QuestionsQuery.gql");

export const CATEGORY_TEMP_ID = "tempid";

export const CategoriesPage: React.FunctionComponent<IProps> = props => {
  const { client } = useContext(getApolloContext());
  const { data, loading } = useQuery<CategoriesQuery>(categoriesQuery, {
    onCompleted: data => {
      if (data && data.categories && client) {
        data.categories.forEach(category => {
          client.query({
            query: questionsQuery,
            variables: { categoryId: category.id },
          });
        });
      }
    },
  });
  const classes = useStyles();

  const [addCategoryMutationFn] = useMutation<AddCategoryMutation>(
    addCategoryMutation,
    {
      update: (cache, res) => {
        if (res && res.data && res.data.addCategory) {
          const categoriesData = cache.readQuery<CategoriesQuery>({
            query: categoriesQuery,
          });

          if (categoriesData) {
            cache.writeQuery<CategoriesQuery>({
              query: categoriesQuery,
              data: {
                categories: [
                  ...categoriesData.categories,
                  res.data.addCategory,
                ],
              },
            });
          }
        }
      },
      optimisticResponse: () => {
        return {
          addCategory: {
            __typename: "Category",
            id: CATEGORY_TEMP_ID,
            name: "",
            questions: [],
          },
        };
      },
    },
  );

  if (loading) {
    return <Spinner></Spinner>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {data.categories.map(category => (
        <Category key={category.id} id={category.id} name={category.name} />
      ))}
      <Tooltip title="Add new category">
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={() => {
            addCategoryMutationFn();
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
