import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

interface IProps {}

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },
}));

export const Spinner: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress></CircularProgress>
    </div>
  );
};
