import React, { useState, useEffect, useContext } from "react";
import { QuestionsQuery_questions } from "../Queries/__generated__/QuestionsQuery";
import {
  Paper,
  TextField,
  makeStyles,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useDebounce } from "../Hooks/useDebounce";
import { ServerSyncContext } from "./ServerSyncStatus";
import {
  QuestionsQuery,
  QuestionsQueryVariables,
} from "../Queries/__generated__/QuestionsQuery";
import {
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables,
} from "../Mutations/__generated__/UpdateQuestionMutation";
import {
  RemoveQuestionMutation,
  RemoveQuestionMutationVariables,
} from "../Mutations/__generated__/RemoveQuestionMutation";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { Option } from "./Option";
import Delete from "@material-ui/icons/Delete";
import { QUESTION_TEMP_ID } from "../Pages/QuestionsPage";

interface IProps {
  question: QuestionsQuery_questions;
}

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(1, 0),
  },
  container: {
    margin: theme.spacing(2),
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const questionsQuery = loader("../Queries/QuestionsQuery.gql");
const updateQuestionMutation = loader(
  "../Mutations/UpdateQuestionMutation.gql",
);
const removeQuestionMutation = loader(
  "../Mutations/RemoveQuestionMutation.gql",
);

export const Question: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const [text, setText] = useState(props.question.text);
  const [correctOptionId, setCorrectOptionId] = useState<string | undefined>(
    undefined,
  );
  const { updateStatus } = useContext(ServerSyncContext);
  const [
    updateQuestionMutationFn,
    { loading: updateQuestionMutationLoading },
  ] = useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(
    updateQuestionMutation,
  );
  const [removeQuestionMutationFn] = useMutation<
    RemoveQuestionMutation,
    RemoveQuestionMutationVariables
  >(removeQuestionMutation, {
    update: (cache, res) => {
      if (res && res.data && res.data.removeQuestion) {
        const removedQuestion = res.data.removeQuestion;

        const questionsData = cache.readQuery<
          QuestionsQuery,
          QuestionsQueryVariables
        >({
          query: questionsQuery,
          variables: {
            categoryId: props.question.category.id,
          },
        });

        if (questionsData) {
          cache.writeQuery<QuestionsQuery, QuestionsQueryVariables>({
            query: questionsQuery,
            data: {
              questions: questionsData.questions.filter(
                question => question.id !== removedQuestion.id,
              ),
            },
            variables: {
              categoryId: props.question.category.id,
            },
          });
        }
      }
    },
    optimisticResponse: {
      removeQuestion: { __typename: "Question", id: props.question.id },
    },
  });

  useEffect(() => {
    setCorrectOptionId(props.question.correctOption.id);
  }, [props.question.correctOption.id]);

  const debouncedText = useDebounce(text, 500);

  useEffect(() => {
    if (
      debouncedText &&
      correctOptionId &&
      (debouncedText !== props.question.text ||
        correctOptionId !== props.question.correctOption.id)
    ) {
      updateQuestionMutationFn({
        variables: {
          id: props.question.id,
          text: debouncedText,
          correctOption: correctOptionId,
        },
        optimisticResponse: {
          updateQuestion: {
            ...props.question,
            text: debouncedText,
            correctOption:
              props.question.options.find(
                option => option.id === correctOptionId,
              ) || props.question.correctOption,
          },
        },
      });
    }
  }, [
    debouncedText,
    correctOptionId,
    props.question.id,
    props.question,
    updateQuestionMutationFn,
  ]);

  useEffect(() => {
    updateStatus(
      `updateQuestion:${props.question.id}`,
      updateQuestionMutationLoading,
    );
  }, [updateQuestionMutationLoading, props.question.id, updateStatus]);

  const disabled = props.question.id === QUESTION_TEMP_ID;

  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <TextField
          label={"Text"}
          margin={"normal"}
          value={text}
          onChange={e => {
            setText(e.target.value);
          }}
          fullWidth
          variant="outlined"
          disabled={disabled}
        />
        <Tooltip title="Remove Question">
          <IconButton
            onClick={() => {
              removeQuestionMutationFn({
                variables: { id: props.question.id },
              });
            }}
            disabled={disabled}
          >
            <Delete />
          </IconButton>
        </Tooltip>
        {props.question.options.map(option => (
          <Option
            key={option.id}
            option={option}
            setCorrectOptionId={setCorrectOptionId}
            correctOption={props.question.correctOption}
            disabled={disabled}
          />
        ))}
      </div>
    </Paper>
  );
};
