import React from "react";
import { makeStyles, Breadcrumbs, Typography, Link } from "@material-ui/core";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import { CategoriesQuery } from "../Queries/__generated__/CategoriesQuery";

interface IProps {}

const useStyles = makeStyles(theme => ({
  container: { padding: "74px 0px 16px 26px" },
}));

const categoriesQuery = loader("../Queries/CategoriesQuery.gql");

export const Navigation: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const location = useLocation();

  const isHome = location.pathname === "/categories";
  const isAtQuestion = location.pathname === "/questions";

  const { data } = useQuery<CategoriesQuery>(categoriesQuery);

  let categoryName = "";

  if (isAtQuestion && data && data.categories) {
    const categoryId = new URLSearchParams(window.location.search).get(
      "categoryId",
    );
    const category = data.categories.find(
      category => category.id === categoryId,
    );

    if (category) {
      categoryName = category.name;
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.container}>
      {isHome && <Typography color="textPrimary">Home</Typography>}

      {!isHome && (
        <Link component={RouterLink} color="inherit" to="/categories">
          Home
        </Link>
      )}
      {isAtQuestion && categoryName && (
        <Typography color="textPrimary">{categoryName}</Typography>
      )}
    </Breadcrumbs>
  );
};
