import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { LoginPage } from "./Pages/LoginPage";
import { QuestionsPage } from "./Pages/QuestionsPage";
import { AnswersPage } from "./Pages/AnswersPage";
import { MeQuery } from "./Queries/__generated__/MeQuery";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { Spinner } from "./Components/Spinner";
import { CategoriesPage } from "./Pages/CategoriesPage";
import { Container } from "@material-ui/core";
import { AppBar } from "./Components/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { ServerSyncStatus } from "./Components/ServerSyncStatus";
import { Navigation } from "./Components/Navigation";

const meQuery = loader("./Queries/MeQuery.gql");

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const App: React.FC = () => {
  const { data, loading } = useQuery<MeQuery>(meQuery);
  const classes = useStyles();

  if (loading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          {data && data.me && data.me.admin ? (
            <Redirect to="/categories"></Redirect>
          ) : (
            <LoginPage></LoginPage>
          )}
        </Route>
        {data && data.me && data.me.admin && (
          <>
            <ServerSyncStatus>
              <AppBar username={data.me.username} />
              <Navigation></Navigation>
              <Container
                component="main"
                maxWidth="lg"
                className={classes.container}
              >
                <Switch>
                  <Route path="/categories">
                    <CategoriesPage></CategoriesPage>
                  </Route>
                  <Route path="/questions">
                    <QuestionsPage></QuestionsPage>
                  </Route>
                  <Route path="/answers">
                    <AnswersPage></AnswersPage>
                  </Route>
                  <Route>
                    <Redirect to="/categories"></Redirect>
                  </Route>
                </Switch>
              </Container>
            </ServerSyncStatus>
          </>
        )}
        <Route>
          <Redirect to="/login"></Redirect>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
