import React, { useMemo, useReducer } from "react";

type UpdateStatusType = (id: string, newStatus: boolean) => void;

interface IServerSyncContext {
  syncing: boolean;
  updateStatus: UpdateStatusType;
}

export const ServerSyncContext = React.createContext<IServerSyncContext>({
  updateStatus: () => {},
  syncing: false,
});

interface IProps {}

interface IState {
  activeMutations: string[];
  syncing: boolean;
}

type Action = { type: "update"; id: string; newStatus: boolean };

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case "update":
      let newActiveMutations;

      if (!action.newStatus) {
        newActiveMutations = state.activeMutations.filter(
          mut => mut !== action.id,
        );
      } else {
        newActiveMutations = [...state.activeMutations, action.id];
      }

      return {
        activeMutations: newActiveMutations,
        syncing: newActiveMutations.length > 0,
      };
    default:
      throw new Error();
  }
};

export const ServerSyncStatus: React.FunctionComponent<IProps> = props => {
  const [state, dispath] = useReducer(reducer, {
    syncing: false,
    activeMutations: [],
  });

  const value: IServerSyncContext = useMemo(
    () => ({
      updateStatus: (id, newStatus) =>
        dispath({ type: "update", id, newStatus }),
      syncing: state.syncing,
    }),
    [state.syncing],
  );

  return (
    <ServerSyncContext.Provider value={value}>
      {props.children}
    </ServerSyncContext.Provider>
  );
};
