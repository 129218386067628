import React, { useState, useEffect, useContext } from "react";
import {
  QuestionsQuery_questions_options,
  QuestionsQuery_questions_correctOption,
} from "../Queries/__generated__/QuestionsQuery";
import { makeStyles, TextField, Tooltip, IconButton } from "@material-ui/core";
import DoneOutline from "@material-ui/icons/DoneOutline";
import {
  UpdateOptionMutation,
  UpdateOptionMutationVariables,
} from "../Mutations/__generated__/UpdateOptionMutation";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { useDebounce } from "../Hooks/useDebounce";
import { ServerSyncContext } from "./ServerSyncStatus";

interface IProps {
  setCorrectOptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  option: QuestionsQuery_questions_options;
  correctOption: QuestionsQuery_questions_correctOption;
  disabled: boolean;
}

const useStyles = makeStyles(theme => ({
  optionContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const updateOptionMutation = loader("../Mutations/UpdateOptionMutation.gql");

export const Option: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const [displayLabel, setDisplayLabel] = useState(props.option.displayLabel);
  const [
    updateOptionMutationFn,
    { loading: updateOptionMutationLoading },
  ] = useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(
    updateOptionMutation,
  );
  const { updateStatus } = useContext(ServerSyncContext);

  const debouncedDisplayLabel = useDebounce(displayLabel, 500);

  useEffect(() => {
    if (
      debouncedDisplayLabel &&
      debouncedDisplayLabel !== props.option.displayLabel
    ) {
      updateOptionMutationFn({
        variables: {
          id: props.option.id,
          displayLabel: debouncedDisplayLabel,
        },
      });
    }
  }, [
    debouncedDisplayLabel,
    props.option.displayLabel,
    props.option.id,
    updateOptionMutationFn,
  ]);

  useEffect(() => {
    updateStatus(
      `updateOption:${props.option.id}`,
      updateOptionMutationLoading,
    );
  }, [updateOptionMutationLoading, props.option.id, updateStatus]);

  return (
    <div className={classes.optionContainer}>
      <TextField
        margin={"normal"}
        value={displayLabel}
        onChange={e => {
          setDisplayLabel(e.target.value);
        }}
        fullWidth
        variant="outlined"
        disabled={props.disabled}
      />
      <Tooltip
        title={
          props.option.id === props.correctOption.id
            ? ""
            : "Set as correct option"
        }
      >
        <div>
          <IconButton
            onClick={() => {
              props.setCorrectOptionId(props.option.id);
            }}
            disabled={
              props.option.id === props.correctOption.id || props.disabled
            }
          >
            <DoneOutline
              color={
                props.option.id === props.correctOption.id
                  ? "primary"
                  : "inherit"
              }
            />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};
