import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { loader } from "graphql.macro";
import {
  LoginMutation,
  LoginMutationVariables,
} from "../Mutations/__generated__/LoginMutation";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router";
import { MeQuery } from "../Queries/__generated__/MeQuery";
import { CircularProgress, Container } from "@material-ui/core";

const loginMutation = loader("../Mutations/LoginMutation.gql");
const meQuery = loader("../Queries/MeQuery.gql");

interface IProps {}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface LoginFormValues {
  usernameOrEmail: string;
  password: string;
}
const loginFormSchema = yup.object().shape<LoginFormValues>({
  usernameOrEmail: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export const LoginPage: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const [loginMutationFn, { loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(loginMutation, {
    update: (cache, res) => {
      if (res.data && res.data.login.user && res.data.login.user.admin) {
        cache.writeQuery<MeQuery>({
          query: meQuery,
          data: { me: res.data.login.user },
        });
      }
    },
  });

  const history = useHistory();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Quiz Bet Admin Pages
        </Typography>
        <Formik<LoginFormValues>
          initialValues={{ usernameOrEmail: "", password: "" }}
          onSubmit={values => {
            loginMutationFn({
              variables: {
                userNameOrEmail: values.usernameOrEmail,
                password: values.password,
              },
            }).then(result => {
              if (
                result &&
                result.data &&
                result.data.login &&
                result.data.login.user &&
                result.data.login.user.admin
              ) {
                history.push("/categories");
              }
            });
          }}
          validationSchema={loginFormSchema}
        >
          {({ values, handleChange, handleBlur, errors, submitCount }) => (
            <Form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="usernameOrEmail"
                label="Username/Email"
                name="usernameOrEmail"
                autoComplete="email"
                autoFocus
                value={values.usernameOrEmail}
                onChange={handleChange("usernameOrEmail")}
                onBlur={handleBlur("usernameOrEmail")}
                helperText={submitCount > 0 && errors.usernameOrEmail}
                error={submitCount > 0 && Boolean(errors.usernameOrEmail)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                helperText={submitCount > 0 && errors.password}
                error={submitCount > 0 && Boolean(errors.password)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                endIcon={
                  loading && <CircularProgress color="secondary" size={20} />
                }
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
