import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import { ServerSyncIndicator } from "./ServerSyncIndicator";
import { Menu, MenuItem } from "@material-ui/core";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { LogoutMutation } from "../Mutations/__generated__/LogoutMutation";

interface IProps {
  username: string;
}

const logoutMutation = loader("../Mutations/LogoutMutation.gql");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const AppBar: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [logoutMutationFn, { client }] = useMutation<LogoutMutation>(
    logoutMutation,
  );

  return (
    <>
      <div className={classes.root}>
        <MuiAppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Person />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.username}
            </Typography>
            <ServerSyncIndicator></ServerSyncIndicator>
          </Toolbar>
        </MuiAppBar>
      </div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            logoutMutationFn().then(res => {
              if (res.data && res.data.logout) {
                setAnchorEl(null);
                if (client) {
                  client.resetStore();
                }
              }
            });
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
