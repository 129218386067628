import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
} from "../Mutations/__generated__/UpdateCategoryMutation";
import {
  RemoveCategoryMutation,
  RemoveCategoryMutationVariables,
} from "../Mutations/__generated__/RemoveCategoryMutation";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { CategoriesQuery } from "../Queries/__generated__/CategoriesQuery";
import Delete from "@material-ui/icons/Delete";
import { useDebounce } from "../Hooks/useDebounce";
import { ServerSyncContext } from "./ServerSyncStatus";
import { useHistory } from "react-router";
import { CATEGORY_TEMP_ID } from "../Pages/CategoriesPage";

interface IProps {
  id: string;
  name: string;
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, 0),
    justifyContent: "space-between",
  },
  item: {
    margin: theme.spacing(1),
  },
}));

const categoriesQuery = loader("../Queries/CategoriesQuery.gql");
const updateCategoryMutation = loader(
  "../Mutations/UpdateCategoryMutation.gql",
);
const removeCategoryMutation = loader(
  "../Mutations/RemoveCategoryMutation.gql",
);

export const Category: React.FunctionComponent<IProps> = props => {
  const [name, setName] = useState(props.name);

  const [
    updateCategoryMutationFn,
    { loading: updateCategoryLoading },
  ] = useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
    updateCategoryMutation,
  );

  const [removeCategoryMutationFn] = useMutation<
    RemoveCategoryMutation,
    RemoveCategoryMutationVariables
  >(removeCategoryMutation, {
    update: (cache, res) => {
      if (res && res.data && res.data.removeCategory) {
        const removedCategory = res.data.removeCategory;

        const categoriesData = cache.readQuery<CategoriesQuery>({
          query: categoriesQuery,
        });

        if (categoriesData) {
          cache.writeQuery<CategoriesQuery>({
            query: categoriesQuery,
            data: {
              categories: categoriesData.categories.filter(
                category => category.id !== removedCategory.id,
              ),
            },
          });
        }
      }
    },
    optimisticResponse: () => {
      return {
        removeCategory: {
          __typename: "Category",
          id: props.id,
          name: props.name,
          questions: [],
        },
      };
    },
  });

  const history = useHistory();

  const { updateStatus } = useContext(ServerSyncContext);

  const debouncedName = useDebounce(name, 500);

  useEffect(() => {
    if (debouncedName && debouncedName !== props.name) {
      updateCategoryMutationFn({
        variables: { id: props.id, name: debouncedName },
      });
    }
  }, [debouncedName, props.id, props.name, updateCategoryMutationFn]);

  useEffect(() => {
    updateStatus(`updateCategory:${props.id}`, updateCategoryLoading);
  }, [updateCategoryLoading, props.id, updateStatus]);

  const classes = useStyles();

  const disabled = props.id === CATEGORY_TEMP_ID;

  return (
    <Paper className={classes.paper}>
      <TextField
        className={classes.item}
        variant="outlined"
        name="name"
        label="Name"
        fullWidth
        id="name"
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        disabled={disabled}
      />
      <Button
        className={classes.item}
        onClick={() => {
          history.push(`/questions?categoryId=${props.id}`);
        }}
        variant={"contained"}
        color="primary"
        disabled={disabled}
      >
        Edit Questions
      </Button>
      <Tooltip title="Remove category">
        <IconButton
          onClick={() => {
            removeCategoryMutationFn({ variables: { id: props.id } });
          }}
          disabled={disabled}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};
