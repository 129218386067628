import React, { useContext, useState, useEffect } from "react";
import { ServerSyncContext } from "./ServerSyncStatus";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import Done from "@material-ui/icons/Done";

interface IProps {}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  typography: {
    marginRight: theme.spacing(),
  },
}));

export const ServerSyncIndicator: React.FunctionComponent<IProps> = props => {
  const serverSyncContext = useContext(ServerSyncContext);
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (serverSyncContext.syncing) {
      setVisible(true);
    }
  }, [serverSyncContext.syncing]);

  if (visible) {
    const { syncing } = serverSyncContext;

    return (
      <div className={classes.container}>
        <Typography className={classes.typography}>
          {syncing ? "Saving" : "Saved"}
        </Typography>
        {syncing && <CircularProgress color="inherit" size={28} />}
        {!syncing && <Done />}
      </div>
    );
  }

  return null;
};
