import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "@apollo/react-hooks";
import { CssBaseline } from "@material-ui/core";
import * as Sentry from "@sentry/browser";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://09e807a607004f779cc721bc11e46edf@sentry.io/1807516",
  });
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(graphQLError => {
          Sentry.withScope(scope => {
            scope.setExtras({
              ...graphQLError,
              variables: operation.variables,
              query: operation.query,
            });
            Sentry.captureException(
              Error(`[GraphQL error]: ${graphQLError.message}`),
            );
          });
          console.error(
            `[GraphQL error]: ${graphQLError.message}`,
            graphQLError,
          );
        });
      if (networkError) {
        Sentry.withScope(scope => {
          scope.setExtras(networkError);
          Sentry.captureException(
            Error(`[Network error]: ${networkError.message}`),
          );
        });
        console.error(`[Network error]: `, networkError);
      }
    }),
    new HttpLink({
      uri: `${process.env.REACT_APP_SERVER_URI}/graphql`,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <>
    <CssBaseline />
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </>,
  document.getElementById("root"),
);
