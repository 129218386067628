import React from "react";
import {
  QuestionsQuery,
  QuestionsQueryVariables,
} from "../Queries/__generated__/QuestionsQuery";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Spinner } from "../Components/Spinner";
import { Question } from "../Components/Question";
import { Tooltip, Fab, makeStyles } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import {
  AddQuestionMutation,
  AddQuestionMutationVariables,
} from "../Mutations/__generated__/AddQuestionMutation";

interface IProps {}

const useStyles = makeStyles(theme => ({
  addButton: {
    alignSelf: "center",
    margin: theme.spacing(2),
  },
}));

const questionsQuery = loader("../Queries/QuestionsQuery.gql");
const addQuestionMutation = loader("../Mutations/AddQuestionMutation.gql");

export const QUESTION_TEMP_ID = "tempid";

export const QuestionsPage: React.FunctionComponent<IProps> = props => {
  const classes = useStyles();

  const params = new URLSearchParams(window.location.search);
  const categoryId = params.get("categoryId") || "";

  const [addQuestionMutationFn] = useMutation<
    AddQuestionMutation,
    AddQuestionMutationVariables
  >(addQuestionMutation, {
    variables: { categoryId },
    update: (cache, res) => {
      if (res && res.data && res.data.addQuestion) {
        const questionsData = cache.readQuery<
          QuestionsQuery,
          QuestionsQueryVariables
        >({
          query: questionsQuery,
          variables: { categoryId: res.data.addQuestion.category.id },
        });

        if (questionsData) {
          cache.writeQuery<QuestionsQuery, QuestionsQueryVariables>({
            query: questionsQuery,
            data: {
              questions: [...questionsData.questions, res.data.addQuestion],
            },
            variables: { categoryId: res.data.addQuestion.category.id },
          });
        }
      }
    },
    optimisticResponse: {
      addQuestion: {
        __typename: "Question",
        id: QUESTION_TEMP_ID,
        text: "",
        correctOption: {
          __typename: "Option",
          displayLabel: "",
          id: "tempOption1",
        },
        options: [
          { __typename: "Option", displayLabel: "", id: "tempOption1" },
          { __typename: "Option", displayLabel: "", id: "tempOption2" },
          { __typename: "Option", displayLabel: "", id: "tempOption3" },
          { __typename: "Option", displayLabel: "", id: "tempOption4" },
        ],
        category: {
          __typename: "Category",
          id: categoryId,
          name: "",
        },
      },
    },
  });

  const { data, loading, error } = useQuery<
    QuestionsQuery,
    QuestionsQueryVariables
  >(questionsQuery, {
    variables: { categoryId },
    skip: !categoryId,
  });

  if (loading) {
    return <Spinner></Spinner>;
  } else if (error) {
    return <>{error.message}</>;
  } else if (!categoryId) {
    return <>Unknown category</>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {data.questions.map(question => (
        <Question key={question.id} question={question}></Question>
      ))}
      <Tooltip title="Add new question">
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={() => {
            if (params.get("categoryId")) {
              addQuestionMutationFn();
            }
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
